<template>
  <div
    class="absolute bottom-5 top-5 left-10 ml-12 z-30 bg-white shadow grid grid-cols-2 pt-5 w-module rounded-xl"
    style="grid-template-rows: auto auto 1fr"
  >
    <div
      class="text-darkblue col-start-1 col-end-2 text-xl font-bold whitespace-no-wrap font-SourceSansPro pr-4 pl-6"
    >
      {{
        title === 1
          ? $t('reports.create_template')
          : $t('reports.edit_template')
      }}
    </div>
    <div class="flex justify-end col-start-2 items-center pr-4 pl-6">
      <i
        class="el-icon-close text-2xl cursor-pointer text-darkblue font-semibold"
        @click="$emit('close')"
      ></i>
    </div>
    <div class="col-start-1 col-end-3 flex mt-4 relative pr-4 pl-6">
      <el-input
        class="w-2/3"
        v-model="reporttemplate.name"
        :class="{ 'input-error': isNameMissing }"
        :placeholder="$t('reports.template')"
        type="text"
      ></el-input>
      <skif-button
        class="ml-6"
        v-if="!isAdmin"
        width="24"
        @click="createOrUpdate"
        >{{ title === 1 ? $t('create') : $t('change') }}
      </skif-button>
      <transition name="el-zoom-in-top">
        <div
          class="text-notify text-xs top-full left-6 absolute"
          v-if="isNameMissing"
        >
          {{ $t('reports.enter_name') }}
        </div>
      </transition>
    </div>
    <!-- mr-4 ml-6 -->
    <div class="col-start-1 col-end-3 overflow-y-auto my-4">
      <table class="w-full table-fixed">
        <thead class="text-left w-full">
          <tr class="border-b border-divider">
            <th class="bg-white top-0 sticky">
              <span
                class="font-bold text-darkblue text-lg font-SourceSansPro pb-3 w-full block pl-6"
                >{{ $t('reports.tables') }}</span
              >
            </th>
            <th align="right">
              <span
                class="pb-3 pr-10 font-bold text-darkblue cursor-pointer"
                @click="
                  $emit('add-table', {
                    reporttemplate: reporttemplate,
                    title: title
                  })
                "
                >{{ $t('add') }}</span
              >
            </th>
          </tr>
        </thead>
        <tbody>
          <draggable
            class="draggable-list"
            @change="dragItem"
            ghost-class="ghost"
            drag-class="drag"
            chosen-class="chosen"
            v-model="sortableTemplateTables"
          >
            <tr
              class="cursor-pointer hover_bg-reallylightblue tr"
              v-for="(table, index) in reporttemplate.template_tables"
              :key="index"
              @mouseover="hoveredRowIndex = index"
              @mouseleave="hoveredRowIndex = null"
              @focus="hoveredRowIndex = index"
              @blur="hoveredRowIndex = null"
              :class="{ 'hovered-row': hoveredRowIndex === index }"
            >
              <td class="ml-4 report-cell w-6">
                <div
                  class="flex items-center justify-center w-full h-full pt-1"
                >
                  <drag-icon />
                </div>
              </td>
              <td class="report-cell flex flex-col w-full">
                <span
                  class="cell-name leading-10 font-bold text-sm text-headerText pl-2"
                  >{{ table.name }}
                </span>
                <transition name="el-fade-in-linear">
                  <p
                    class="table-id-tooltip pl-2"
                    v-if="
                      hoveredRowIndex === index && table.default_template_table
                    "
                  >
                    {{ $t('type') }}: {{ table.default_template_table.name }}
                  </p>
                </transition>
              </td>
              <td>
                <div class="flex pt-1">
                  <svg
                    class="cursor-pointer mr-2"
                    width="24"
                    height="24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    @click="
                      $emit('update-table', {
                        table: table,
                        index: index,
                        isAdmin: isAdmin
                      })
                    "
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M4 8a4 4 0 014-4h3a1 1 0 110 2H8a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1 1 0 112 0v3a4 4 0 01-4 4H8a4 4 0 01-4-4V8zm14.293-3.707a1 1 0 111.414 1.414l-7 7a1 1 0 01-1.414-1.414l7-7z"
                      fill="#91B4E7"
                    ></path>
                    <defs>
                      <clippath id="clip0">
                        <path
                          fill="#fff"
                          transform="translate(4 4)"
                          d="M0 0h16v16H0z"
                        ></path>
                      </clippath>
                    </defs>
                  </svg>
                  <svg
                    class="cursor-pointer"
                    width="24"
                    height="24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    @click="deleteTable(table.id)"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M9 5H6v2h12V5h-3l-1-1h-4L9 5zm8 3H7v10a2 2 0 002 2h6a2 2 0 002-2V8z"
                      fill="#91B4E7"
                    ></path>
                  </svg>
                </div>
              </td></tr
          ></draggable>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import draggable from 'vuedraggable'
import DragIcon from '@/components/reports/icons/DragIcon.vue'

export default {
  components: {
    draggable,
    DragIcon
  },
  props: {
    title: Number,
    reporttemplate: {
      type: Object,
      default: () => {
        return {
          name: '',
          template_tables: []
        }
      }
    }
  },
  data() {
    return {
      hoveredRowIndex: null,
      isNameMissing: false,
      isAdmin: false
    }
  },
  computed: {
    ...mapGetters('reports', { templatesForAdding: 'templatesForAdding' }),
    ...mapState('reports', ['templatesSensors']),
    sortableTemplateTables: {
      get() {
        return this.reporttemplate.template_tables
      },
      set(newValue) {
        this.reporttemplate.template_tables = newValue
      }
    }
  },

  watch: {
    'reporttemplate.name': function (val) {
      this.isNameMissing = val === ''
    }
  },
  methods: {
    dragItem({ moved }) {
      if (this.title !== 1) {
        this.$store.dispatch('reports/UPDATE_TEMPLATE_TABLES', {
          tables: moved,
          id: this.reporttemplate.id
        })
        this.$emit('updateReportTemplate', this.sortableTemplateTables)
      }
    },
    valid() {
      let error = true
      if (!this.reporttemplate.name) {
        this.isNameMissing = !this.reporttemplate.name
        error = false
      }
      return error
    },
    createOrUpdate() {
      this.reporttemplate.template_tables.forEach((val) => {
        if (val.template_sensors) {
          val.template_sensors.forEach((item) => {
            if (item.id) {
              delete item.id
            }
          })
        }
      })
      this.reporttemplate.template_tables.forEach((val) => {
        if (val.template_computable_columns) {
          val.template_computable_columns.forEach((item) => {
            if (item.id) {
              delete item.id
            }
          })
        }
      })

      if (this.valid()) {
        if (this.title === 1) {
          this.$store.dispatch('reports/SAVE_TEMPLATES_SENSOR', [])
          this.$store
            .dispatch('reports/CREATE_TEMPLATE', this.reporttemplate)
            .then(() => this.$emit('close'))
        } else {
          this.$emit('updateReportTemplate', this.sortableTemplateTables)
          // this.$emit('close')
          this.$store
            .dispatch('reports/UPDATE_TEMPLATE', this.reporttemplate)
            .then(() => this.$emit('close'))
        }
      }
    },
    deleteTable(id) {
      this.$emit('update:reporttemplate', {
        ...this.reporttemplate,
        template_tables: [
          ...this.reporttemplate.template_tables.filter(
            (table) => table.id !== id
          )
        ]
      })
    }
  },
  created() {
    this.isAdmin = this.reporttemplate.isAdmin
  }
}
</script>
<style lang="scss" scoped>
@import '@/components/reports/assets/styles/addReporttemplate.scss';
</style>
